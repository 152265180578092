import React, { useState, useRef } from 'react';

import Box from '@mui/material/Box';

const ContactFormEmailInput = (props) => {
  const { padding = 16 } = props;

  const {
    marginBottom = '19px',
  } = props;

  const [currentEmail, setCurrentEmail] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [timerHandle, setTimerHandle] = useState(null);

  const emailInput = useRef();

  const validateCurrentEmail = () => {
    if (typeof currentEmail !== 'string' && !(currentEmail instanceof String)) {
      setIsValid(false);
      return;
    }
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const matches = currentEmail.match(regex);
    // console.log('email_pane.onChangeHandler() matches: ', matches);
    if (matches && matches[0]) {
      setIsValid(true);
      setIsDirty(false);
    } else {
      setIsValid(false);
    }
  };

  const startTimer = () => {
    const handle = setTimeout(() => {
      setIsDirty(true);
    }, 1000);
    setTimerHandle(handle);
  };

  const onChangeHandler = (event) => {
    if (timerHandle) clearTimeout(timerHandle);
    // console.log('email_pane.onChangeHandler() event.target.value: ', event.target.value);
    props.onChange(event.target.value);
    setCurrentEmail(event.target.value);
    validateCurrentEmail();
    startTimer();
  };

  const errorMessage = (currentEmail && isDirty && !isValid) ? "Please enter a valid email." : "";

  return (
    <Box 
      sx={{
        position: 'relative',
        marginBottom: marginBottom,
      }}
    >
      <input 
        type="email" 
        ref={emailInput}
        placeholder="Email address" 
        onChange={onChangeHandler}
        style={{
          width: `calc(100% - ${padding * 2}px)`,
          height: '65px',
          borderRadius: '20px',
          background: '#FFFFFF',
          fontSize: '24px',
          border: 'none',
          boxSizing: 'border-box',
          paddingLeft: '20px',
          outline: 'none',
          color: '#787879',
          border: '1px solid #999999',       
        }}
      />

      <Box
        sx={{
          position: 'relative',
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          color: '#ffffff',
          fontSize: '0.875rem',
          minHeight: '1.2rem',
        }}
      >
        {errorMessage}
      </Box>

    </Box>
  )
}

export default ContactFormEmailInput;