import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';


const ContactFormConfirmationPane = (props) => {  
  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '1rem',
        padding: 0,
        height: '100%'
      }}
    >
      {props.status !== 'error' && 
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              paddingLeft: { xs: '16px', lg: '60px' },
              paddingRight: { xs: '16px', lg: '60px' },
              paddingTop: { xs: '1rem', lg: '0px' },
              paddingBottom: { xs: '1rem', md: '60px', lg: '60px', xl: '40px' },
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '30px',
              color: '#ffffff',
              textAlign: 'center'
            }}
          >
            Thanks for leaving your contact information. We'll be in touch soon.
          </Box>

          <Box
            sx={{
              width: { xs: '60%', sm: '40%', md: '30%', lg: '40%' },
              margin: '0 auto',
            }}
          >
            <StaticImage
                src="../../images/Bendable_B_T_lockup_circle.png" 
                alt="Bendable + BT"
              />
          </Box>                

        </Box>

      }
      {props.status === 'error' && 
        <Box
          sx={{
            width: '100%',
            paddingLeft: { xs: '16px', lg: '60px' },
            paddingRight: { xs: '16px', lg: '60px' },
            paddingTop: { xs: '1rem', lg: '120px' },
            fontFamily: 'Sharp Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '30px',
            color: '#ffffff',
            textAlign: 'center'
          }}
        >
          Sorry! Something went wrong on our end. Please try again later.
        </Box>
      }
    </Box>
  )
}

export default ContactFormConfirmationPane;