import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ContactFormButton = (props) => {
  const { 
    text,
    color = '#000000',
    backgroundColor = '#ffffff',
    centerInParent = true,
    paddingTop = '20px',
    onClick
  } = props;

  return (
    <Box
      component="div"
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: '68px',
        width: { xs:'219px', lg: '219px' },
        paddingTop: paddingTop,
        paddingBottom: '20px',
        paddingLeft: '20px', 
        paddingRight: '20px',
        background: backgroundColor,        // '#1B7392',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: '40px',
        margin: centerInParent ? '0 auto' : 'inherit'
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: { xs:'18px', lg: '25px'},
          lineHeight: '28px',
          color: color
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ContactFormButton;